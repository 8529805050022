import React from 'react'
import { withSnackbar } from 'notistack'

import { makeStyles } from '@material-ui/core/styles'
import { Button, Grid, GridList, Typography } from '@material-ui/core'

import { FONTS, URLs } from '../utils/core'
import Colors from '../utils/colors'
import CustomLink from '../components/link'
import Icon from '../components/icon'
import Page from './page'
import Stripe from '../utils/stripe'
import Timeline from '../components/timeline'
import Window from '../utils/window'
import Logger from '../utils/logger'

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
  },
  main: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-evenly',
    margin: 20,
  },
  content: {
    justifyContent: 'space-evenly',
  },
  mainContent: {
    minWidth: 350,
  },
  header: {
    fontFamily: FONTS.primary,
    fontSize: 'x-large',
    textAlign: 'center',
    margin: 10,
  },
  details: {
    fontFamily: FONTS.primary,
    fontWeight: 300,
    textAlign: 'center',
    margin: '1em',
  },
  buttons: {
    minWidth: '50%',
    width: 'initial !important',
    textAlign: 'center',
  },
  note: {
    fontFamily: FONTS.primary,
    fontWeight: 300,
    fontSize: 'small',
    textAlign: 'center',
    margin: 10,
  },
  email: {
    color: Colors.get('black'),
    fontWeight: 'bold',
    textDecoration: 'none',
  },
  timeline: {
    minWidth: 375,
  },
}))

function Review() {
  const classes = useStyles()

  React.useEffect(() => {
    if (window.location.hash === '#error') {
      Window.alert('Error with payment', { variant: 'error' })
    }
  }, [])

  function content() {
    return (
      <Grid container className={classes.container}>
        {/** MAIN CONTENT */}
        <Grid className={classes.main}>
          <GridList cellHeight={'auto'} cols={2} className={classes.content}>
            <Grid item className={classes.mainContent}>
              <Typography className={classes.header}>
                Get your Resume Reviewed
              </Typography>
              <Typography className={classes.details}>
                I've obsessed over my resume, and I'll obsess over yours too.{' '}
                {String.fromCodePoint('0x1F913')}
              </Typography>
              <Typography className={classes.details}>
                My resume expertise comes from all the hours I stressed over my
                personalized resume to help me stand out to <b>Google</b>{' '}
                <u>when I had no job experience</u> and{' '}
                <b>Harvard Business School</b>{' '}
                <u>when I had no business experience</u>. My resume also got
                noticed by other companies - like Microsoft and Nike - before I
                accepted my current positions.
              </Typography>
              <Typography className={classes.details}>
                My goal is to have all my tips available for free{' '}
                <CustomLink
                  text="on my YouTube channel"
                  event="YouTube"
                  url={URLs.YouTube}
                  color="red"
                />
                , but until I get there, I can review your resume for half of
                what{' '}
                <CustomLink
                  text="other"
                  event="resume_competitor"
                  url="https://www.joiwade.com/services/resume"
                  color="cyan"
                />{' '}
                <CustomLink
                  text="people"
                  event="resume_competitor"
                  url="https://earlyadmit.com/deferred-mba-consulting/"
                  color="blue"
                />{' '}
                charge.
              </Typography>
              <Grid item className={classes.buttons}>
                <Button
                  startIcon={<Icon src="review" color="white" />}
                  style={{
                    backgroundColor: Colors.get('blue'),
                    color: Colors.get('white'),
                  }}
                  className={classes.button}
                  onClick={() => {
                    Logger.log('resume_stripe_checkout')
                    Stripe.redirectToCheckout()
                  }}
                >
                  Resume Review, $20
                </Button>
                <Typography className={classes.note}>
                  Resume upload occurs after payment.
                </Typography>
                <Typography className={classes.note}>
                  Running into issues? Email me directly at{' '}
                  <a
                    href={`mailto:${URLs.resume_email}`}
                    className={classes.email}
                  >
                    {URLs.resume_email}
                  </a>
                </Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.timeline}>
              <Timeline />
            </Grid>
          </GridList>
        </Grid>
      </Grid>
    )
  }

  return <Page content={content()} />
}

export default withSnackbar(Review)
