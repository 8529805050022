import _ from 'lodash'
import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
} from '@material-ui/lab'
import { Avatar, CardMedia, Paper, Typography } from '@material-ui/core'

import { img_embed } from '../utils/core'
import Colors from '../utils/colors'
import data from '../data/timeline'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  dot: {
    backgroundColor: Colors.get('white'),
  },
  oppositeText: {
    width: '100%',
    paddingTop: '1em',
  },
  img: {
    height: 100,
    paddingTop: '56.25%', // 16:9
    margin: 5,
  },
  title: {
    fontSize: '1rem',
  },
  subtitle: {
    fontSize: '0.75rem',
  },
}))

export default function CustomizedTimeline() {
  const classes = useStyles()

  return (
    <Timeline align="alternate">
      {_.map(data, (obj, idx) => (
        <TimelineItem key={idx}>
          <TimelineOppositeContent className={classes.opposite}>
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.oppositeText}
            >
              {obj.opposite}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={classes.dot}>
              <Avatar aria-label="logo" src={img_embed(obj.avatar)} />
            </TimelineDot>
            {idx === data.length - 1 ? null : <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent
            style={{ visibility: obj.title ? 'initial' : 'hidden' }}
          >
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="h6" component="h1" className={classes.title}>
                {obj.title}
              </Typography>
              <Typography className={classes.subtitle}>
                {obj.subtitle}
              </Typography>
            </Paper>
            {obj.img ? (
              <CardMedia
                className={classes.img}
                image={img_embed(obj.img)}
                title={obj.title}
              />
            ) : null}
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  )
}
