import IMAGES from '../data/images'

const data = [
  {
    opposite: null,
    avatar: IMAGES.me.avatar,
    title: null,
    subtitle: null,
  },
  {
    opposite: '2018',
    avatar: IMAGES.google.avatar,
    title: 'Google',
    subtitle: 'Software Engineering',
    img: IMAGES.google.img,
  },
  {
    opposite: '2019 - present',
    avatar: IMAGES.facebook.avatar,
    title: 'Facebook',
    subtitle: 'Solutions Engineering',
    img: IMAGES.facebook.img,
  },
  {
    opposite: 'Entering 2022 (deferred)',
    avatar: IMAGES.harvard.avatar,
    title: 'Harvard',
    subtitle: 'MBA, 2+2 Program',
    img: IMAGES.harvard.img,
  },
]

export default data
