import React from 'react'
import PropTypes from 'prop-types'

import Colors from '../utils/colors'
import Logger from '../utils/logger'

export default function CustomLink({ text, event, url, color }) {
  return (
    <a
      href={url}
      onClick={() => Logger.log(event)}
      style={{
        color: Colors.get(color),
        cursor: 'pointer',
        textDecoration: 'underline',
      }}
      target="_blank"
      rel="noreferrer"
    >
      {text}
    </a>
  )
}

CustomLink.propTypes = {
  text: PropTypes.string.isRequired,
  event: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  color: PropTypes.string,
}

CustomLink.defaultProps = {
  color: 'blue',
}
