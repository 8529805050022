import REST from './rest'

const KEY =
  process.env.GATSBY_BACKEND === 'local'
    ? 'pk_test_51I2vN6AeIjm4B6nFAaeI1leCxmusdLXbtobylvVzfTbMnLrbeNEdYoNkckp2xsjrpjgQy2WYqy38Gx9O77xL114f00H8NjOwTk'
    : 'pk_live_51I2vN6AeIjm4B6nFxZyHN1VQs1I0pmiceo3160eZ6FF7GgelVgI3MUtGAahxK0xif4LRZjoEwW9BbIuq9tR2dnJP00zgENXm5A'

export default class StripeWrapper {
  static redirectToCheckout() {
    const stripe = window.Stripe(KEY)
    REST.post('resumes/initiate')
      .then(session => stripe.redirectToCheckout({ sessionId: session.id }))
      .then(result => {
        if (result.error) {
          window.alert('There was an issue')
        }
      })
      .catch(error => {
        console.error('Error: ', error)
      })
  }

  static completePurchase(confirmation) {
    return REST.post('resumes/complete', { session_id: confirmation })
  }
}
